<template>
  <!--  首页-->
  <main ref="main">
    <!--防止闪屏mask， 不能放进video-view中，否则会和video一起挂载，还是会闪屏-->
    <div class="video-view__mask" ref="mask"></div>
    <video-view ref="videoView"/>

    <!--首页新闻-->
    <news-view/>

    <!--首页集团业务-->
    <business-view/>

    <!--首页合作伙伴-->
    <section class="partner-view">
      <img src="../../assets/img/title_partners@2x.png" alt="合作伙伴" class="partner-view__title">
      <div class="partner-view__logo-container" ref="logo">
        <a href="http://ipwm.org/" target="_blank">
          <img src="../../assets/img/logo_04@2x.png" alt="国际专业财富管理协会">
        </a>
        <a href="http://www.fqchildren.com/" target="_blank">
          <img src="../../assets/img/logo_03@2x.png" alt="财商少年">
        </a>
      </div>
    </section>
  </main>
</template>

<script>
  import VideoView from "./childComp/VideoView"
  import NewsView from "./childComp/NewsView"
  import BusinessView from "./childComp/BusinessView"

  export default {
    name: "home",
    components: {
      VideoView,
      NewsView,
      BusinessView,
    },
    metaInfo: {
      title: '乐享无限官网',
      meta: [
        {
          name: 'keyWords',
          content: '乐享无限,乐享无限官网,乐享无限综合性自我增值平台,智菁通,财务规划',
        },
        {
          name: 'description',
          content: '乐享无限集团拥有近二十年的财务管理与规划的专业知识,建立了财务规划行业的独特成功模式。乐享无限培养专业的理财师,帮助大众做好个人及家庭的财务规划,实现财务自由。'
        }
      ]
    },
    mounted() {
      let handleScroll = () => {
        let bottom = window.innerHeight + window.pageYOffset
        if (bottom >= this.$refs.logo.offsetTop) {
          this.$refs.logo.classList.add('fly-in-from-bottom')
          window.removeEventListener('scroll', handleScroll)
        }
      }
      window.addEventListener('scroll', handleScroll)
      this.$refs.videoView.$refs.video.onplay = () => {
        this.$refs.mask.style.display = 'none'
      }
    }
  }
</script>

<style lang="scss" scoped>
.partner-view {
  margin-top: 125px;
  .partner-view__title {
    display: block;
    margin: 0 auto;
  }
  text-align: center;
  .partner-view__logo-container {
    display: inline-block;
    margin-top: 60px;
    margin-bottom: 125px;
    a {
      display: inline-block;
      padding: 20px;
      transition: box-shadow 0.5s;
      &:hover {
        box-shadow: 0 0 5px 3px #e2ecf4;
      }
    }
    a:first-child {
      margin-right: 390px;
    }
  }
}
.video-view__mask {
  width: 100%;
  height: 100%;
  background: #0e0e0e;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
}
</style>

<style lang="scss">
  @keyframes fly-bottom {
    0% {transform: translateY(200%);}
    100% {transform: translateX(0);}
  }
  .fly-in-from-bottom {
    animation: fly-bottom 1s;
  }
</style>
