<template>
  <div class="video-view">
    <div class="video-cutter" :style="{height: cutterHeight + 'px'}">
      <div class="video-container"
           :style="{width: containerWidth + 'px', height: containerHeight + 'px', marginLeft: containerMarginLeft + 'px', marginTop: containerMarginTop + 'px'}">
        <video autoplay loop :muted="isMute" height="100%" width="100%" ref="video">
          <source src="../../../assets/乐享简介.mp4" type="video/mp4">
          当前浏览器不支持video直接播放
        </video>
      </div>
    </div>
    <img src="../../../assets/img/pic_01@2x.png" alt="" class="video-view__logo">
 </div>
</template>

<script>
// 720p视频比例
const RATE = 720 / 1280
export default {
  name: 'VideoView',
  data() {
    return {
      containerHeight: 0,
      containerWidth: 0,
      containerMarginTop: 0,
      containerMarginLeft: 0,
      cutterHeight: 0,
      isMute: true
    }
  },
  methods: {
    handleResize() {
      let height = window.innerHeight
      let width = window.innerWidth
      let rate = height / width
      this.cutterHeight = height
      if(rate > RATE) {
        this.containerHeight = height
        this.containerWidth = height / RATE
        this.containerMarginLeft = - (this.containerWidth - width) / 2
        this.containerMarginTop = 0
      } else if(rate <= RATE) {
        this.containerWidth = width
        this.containerHeight = width * RATE
        this.containerMarginTop = - (this.containerHeight - height) / 2
        this.containerMarginLeft = 0
      }
    }
  },
  created() {
    window.onload = () => {
      this.handleResize()
      window.onresize = this.handleResize
    }
  },
}
</script>

<style scoped lang="scss">
  .video-view {
    position: relative;
  }
  .video-view__logo {
    position: absolute;
    /*bottom: 35%;*/
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
  .video-cutter {
    overflow: hidden;
    width: 100%;
  }
</style>
